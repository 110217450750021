import { useRef } from "react";
import ZoomPaper from "./ZoomPaper";
import MobileAppDisplay from "../assets/MobileAppDisplay.png";
import WebDevelopmentDisplay from "../assets/WebDevelopmentDisplay.png";
import DigitalTwinDisplay from "../assets/DigitalTwinDisplay.png";
import DigitalMarketingDisplay from "../assets/DigitalMarketingDisplay.png";
import CloudComputingDisplay from "../assets/CloudComputingDisplay.png";
import TaskAutomationDisplay from "../assets/TaskAutomationDisplay.png";
import SoftwareDevelopmentDisplay from "../assets/SoftwareDevelopmentDisplay.png";

import DigitalTwin1 from "../assets/DigitalTwin1.png";
import MobileDevelopment1 from "../assets/MobileDevelopment1.png";
import SoftwareDevelopment1 from "../assets/SoftwareDevelopment1.png";
import TaskAutomation1 from "../assets/TaskAutomation1.png";
import DigitalMarketing1 from "../assets/DigitalMarketing1.png";

import { Fade, Typography } from "@mui/material";

const ServicesSection = () => {

  const scrollRef = useRef<HTMLDivElement>(null);

  
  const services = [
    {
      title: "Web Application Development",
      description: "We build websites and web applications using the latest technologies. We can help you with your next project.",
      image: WebDevelopmentDisplay,
      expandedImages: [],
      textContent: [
        "Custom Web Development Solutions: Our company specializes in custom web development solutions tailored for businesses globally.",
        "Comprehensive Service Offerings: We provide a wide range of services, including responsive website design, e-commerce platforms, and content management systems (CMS), ensuring your online presence is modern, mobile-friendly, and user-focused.",
        "UI/UX Design and Web Application Development: With our expertise in UI/UX design and web application development, we create websites that are visually appealing and deliver seamless user experiences.",
        "SEO Optimization and E-commerce Solutions: Whether you need an SEO-optimized business website, a custom WordPress site, or an advanced Shopify store, our team is dedicated to bringing your vision to life with the latest industry standards."
      ],
      size: { width: 550, height: 350 }
    },
    {
      title: "Custom Software Development",
      description: "We build custom software solutions for all your IT needs. We can help you with your next project.",
      image: SoftwareDevelopmentDisplay,
      expandedImages: [SoftwareDevelopment1, SoftwareDevelopment1],
      textContent: [
        "Tailored Software Development Solutions: We offer custom software development and bespoke software solutions designed to meet the unique needs of businesses across Globally, addressing specific challenges, enhancing productivity, and streamlining business processes.",
        "Scalable Enterprise and SaaS Applications: Our expertise includes enterprise software development for large-scale operations and the creation of cloud-based SaaS applications that effectively serve clients and improve internal processes.",
        "Seamless Software Integration and Cloud Solutions: We provide software integration services to ensure seamless connectivity between existing systems and new applications, alongside cloud-based software solutions that offer flexibility and accessibility from anywhere.",
        "Agile Development and Ongoing Support: Utilizing agile development practices, we involve clients throughout the process to ensure continuous progress, complemented by software maintenance and support to keep solutions up-to-date, enhance business software automation, and deliver high-quality, innovative solutions that empower businesses to thrive in the digital era."
      ],
      size: { width: 500, height: 300 }
    },
    {
      title: "Mobile App Development",
      description: "We build mobile applications for both iOS and Android. We can help you with your next project.",
      image: MobileAppDisplay,
      expandedImages: [MobileDevelopment1],
      textContent: [
        "Custom Mobile App Development Solutions: We specialize in providing custom mobile app development services for businesses across Globally, creating tailored iOS and Android apps that ensure seamless performance across devices.",
        "Cross-Platform and Enterprise Applications: Our offerings include cross-platform mobile app development, as well as native and hybrid apps and enterprise mobile applications, designed to effectively connect your business with users.",
        "Engaging UI/UX Design: With a strong focus on mobile app UI/UX design, we deliver engaging, intuitive, and user-friendly interfaces that enhance user interaction and overall experience.",
        "Precision Development and Ongoing Maintenance: Whether for a startup or a scalable enterprise solution, our team of experts ensures precise app development, supported by rigorous app testing and ongoing maintenance services to guarantee optimal performance."
      ],
      size: { width: 450, height: 800 }
    },
    {
      title: "Cloud Infrastructure Solutions",
      description: "We provide cloud infrastructure solutions for all your IT needs. We can help you with your next project.",
      image: CloudComputingDisplay,
      expandedImages: [],
      textContent: [
        "Comprehensive Cloud Solutions: We offer a wide range of cloud computing services, including cloud migration, cloud-based infrastructure, and managed cloud services, to help businesses in Globally transition to the cloud and optimize digital transformation.",
        "Hybrid and Multi-Cloud Strategies: Our team deploys hybrid cloud and multi-cloud strategies, leveraging platforms like AWS, Azure, and Google Cloud for tailored flexibility, scalability, and cost-efficiency.",
        "Cloud Security and Data Management: We prioritize cloud security and compliance, ensuring your data is protected while providing cloud storage and backup services for data availability and disaster recovery.",
        "DevOps and Cloud Automation: With a focus on DevOps and cloud automation, we streamline deployment processes for faster time-to-market and improved operational efficiency, supporting your growth objectives across Globally."
      ],
      size: { width: 550, height: 300 }
    },
    {
      title: "Task Automation Solutions",
      description: "We provide task automation solutions for all your IT needs. We can help you with your next project.",
      image: TaskAutomationDisplay,
      expandedImages: [TaskAutomation1],
      textContent: [
        "Task Automation Solutions: We provide task automation solutions designed to streamline business operations for organizations across Globally, specializing in business process automation to automate repetitive tasks and improve productivity.",
        "Workflow and Robotic Process Automation (RPA): Our services include workflow automation and robotic process automation (RPA), enabling software robots to mimic human actions for tasks such as data entry, data processing, and customer service, allowing your team to focus on strategic initiatives.",
        "Custom Automation Software and AI Integration: We develop custom automation software tailored to your unique business needs, ensuring seamless integration across multiple systems. Our solutions leverage AI-powered automation to make smarter decisions, identify process bottlenecks, and optimize workflow efficiency.",
        "Scalable Automated Business Workflows: Whether you are a startup, a small business, or a large enterprise, our automated business workflows are scalable and adaptable, committed to delivering innovative, reliable, and cost-effective task automation solutions that drive digital transformation and enhance operational efficiency."
      ],
      size: { width: 600, height: 450 }
    },
    {
      title: "Digital Marketing and SEO",
      description: "We provide digital marketing and SEO services for all your IT needs. We can help you with your next project.",
      image: DigitalMarketingDisplay,
      expandedImages: [DigitalMarketing1],
      textContent: [
        "Comprehensive Digital Marketing & SEO Services: We help businesses grow their online presence across Globally through effective search engine optimization (SEO) strategies that improve website visibility on major search engines like Google and Bing, driving more organic traffic.",
        "Targeted Marketing Strategies: Our services include local SEO to enhance rankings in local searches, social media marketing (SMM) to create and manage campaigns on platforms like Facebook, Instagram, LinkedIn, and Twitter, and PPC advertising to manage Google Ads campaigns that drive targeted traffic and increase conversions.",
        "Content and E-commerce Marketing: We develop engaging content that resonates with your audience, boosts brand awareness, and includes e-commerce marketing strategies to optimize product listings, manage promotions, and improve conversion rates for online stores.",
        "Comprehensive Engagement and Reputation Management: Our approach also includes email marketing campaigns with personalized, data-driven strategies to nurture leads, online reputation management (ORM) to maintain a positive brand image, and tailored solutions designed to generate leads and build a strong brand presence across Globally"
      ],
      size: { width: 300, height: 350 }
    },
    {
      title: "Digital Twin Solutions",
      description: "We provide digital twin solutions for all your IT needs. We can help you with your next project.",
      image: DigitalTwinDisplay,
      expandedImages: [DigitalTwin1],
      textContent: [
        "Industrial Digital Twins: We create virtual replicas of physical assets for real-time performance monitoring and predictive maintenance, enhancing operational efficiency across various industries.",
        "IoT Integration and Smart Manufacturing: Our solutions utilize sensor data and analytics for proactive issue resolution, streamlining production, resource management, and quality control through digital twin technology.",
        "Applications Across Multiple Sectors: We provide construction applications to enhance project visualization, progress tracking, and asset management, as well as healthcare solutions that simulate patient conditions and optimize equipment performance for improved care.",
        "Data-Driven Insights and Custom Solutions: Our 3D visualization & analytics capabilities enable accurate digital simulations for informed decision-making, offering custom, scalable solutions tailored to meet specific industry needs across Globally."
      ],
      size: { width: 500, height: 400 }
    },
  ];

  const wrappedServices = [...services];

  return (
    <div id={'services'} style={{marginLeft: 15, marginRight: 15}}>
      <Typography variant="h2" component="h2" gutterBottom> Services </Typography>
      <div
        ref={scrollRef}
        className="scroll-container"
        style={{
          display: 'flex',
          margin: '0 auto',
          padding: '0 0',
          flexDirection: 'row',
          alignItems: 'center',
          overflow: 'scroll',
          width: '100%', // Ensure it has a width
          scrollBehavior: 'smooth',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}>
        {wrappedServices.map((service, index) => (
          <Fade in={true} style={{ transitionDelay: `${index * 500}ms` }} key={index}>
            <div style={{ minWidth: '300px', margin: '0 10px' }}>
              <ZoomPaper textContent={service.textContent} expandedImages={service.expandedImages} size={service.size} image={service.image} title={service.title} text={service.description} />
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;