// src/components/CustomerAppBar.tsx
import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ThemeToggle from './ThemeToggle';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Logo from './Logo';

interface CustomerAppBarProps {
  scrolled: boolean;
}

const CustomAppBar: React.FC<CustomerAppBarProps> = ({ scrolled }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const menuItems = [
    { text: 'Services', onClick: () => scrollToSection('services') },
    { text: 'Portfolio', onClick: () => scrollToSection('portfolio') },
    { text: 'About Us', onClick: () => scrollToSection('about-us') },
    { text: 'Contact Us', onClick: () => scrollToSection('contact-us') },
  ];

  const gradientColor = `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.background.default})`;

  const scrollToSection = (id: string) => {
    if (id === 'home') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setOpen(false);
      return;
    }
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      setOpen(false);
    }
    
  };
  

  const buildMobileMenu = () => {
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
        background: 'transparent',
        width: '50%',
        alignSelf: 'center',
        marginTop: '6em',
        marginRight: '1em',
        borderRadius: 2,
        height: '50%',
          },
        }}
      >
        <List sx={{paddingLeft: 3}}>
          <ListItem> <ThemeToggle /> </ListItem>
          {menuItems.map((item, index) => (
            <ListItem key={index} onClick={item.onClick}>
              <ListItemText
              primary={item.text}
            primaryTypographyProps={{ fontSize: '1.5rem', fontWeight: 'bold' }}
            sx={{ '&:hover': {
              cursor: 'pointer',
              color: theme.palette.secondary.contrastText,
            }}}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    );
  };

  const buildDesktopMenu = () => {
    return (
      <>
        {menuItems.map((item, index) => (
          <Typography
            key={index}
            variant="h6"
            sx={{ flexGrow: 1, '&:hover': {
              cursor: 'pointer',
              color: theme.palette.secondary.contrastText,
            }}}
            onClick={item.onClick}
            textAlign={'center'}
          >
            {item.text}
          </Typography>
        ))}
        <ThemeToggle />
      </>
    );
  };

  return (
    <>
      {isMobile && buildMobileMenu()}
      <AppBar
        position="fixed"
        sx={{
          background: scrolled ? gradientColor : 'transparent',
          boxShadow: scrolled ? 2 : 'none',
          height: '6em',
          justifyContent: 'center',
          transition: 'background-color 0.3s ease',
        }}
      >
        <Toolbar>
          <div onClick={() => scrollToSection('home')}>
            <Logo />
          </div>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: 'flex', marginLeft: 'auto' }}
              onClick={() => setOpen(true)}
            >
              {!open ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
          ) : (
            buildDesktopMenu()
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default CustomAppBar;
