import React from 'react';
import { Dialog, DialogContent, Grid2, Slide, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import WebhookIcon from '@mui/icons-material/Webhook';

interface ExpandedServiceProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cardInfoProps: {
    title: string;
    description: string;
    image: string;
    expandedImages: string[];
    textContent: string[];
  }
}

const ExpandedService: React.FC<ExpandedServiceProps> = ({ open, setOpen, cardInfoProps }) => {
  const theme = useTheme();
  const gradientColor = `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.background.default})`;
  const isMobile = window.innerWidth < 600;

  const handleClose = () => {
    setOpen(false);
  };

  const buildLayout = () => {
    return (
      <>
        {cardInfoProps?.textContent && cardInfoProps.textContent.length > 0 && (
        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
          {cardInfoProps.textContent.map((text, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Slide in={true} direction='right' timeout={1000*index}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <WebhookIcon style={{ marginRight: 20, color: 'white' }} />
              <Typography variant="body1" textAlign={'justify'} lineHeight={2}>
                {text}
              </Typography>
            </div>
          </Slide>
          </li>
          ))}
        </ul>
        )}
      </>
    );
  };  

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 500 }}
      sx={{
        '& .MuiDialog-paper': {
          width: !isMobile ? '60%' : '90%',
          maxWidth: 'none',
          margin: 'auto',
          height: '90%',
        },
      }}
    >
      <DialogContent dividers sx={{ background: gradientColor, opacity: 0.8}}>
        <Typography variant="h4" component="h2" sx={{ color: 'white', textAlign: 'center', padding: 2 }}>
          {cardInfoProps?.title}
        </Typography>
        <Grid2 container justifyContent="center" alignItems="center">
          <CloseIcon onClick={handleClose} sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }} />
        </Grid2>
        {buildLayout()}
        <img src={cardInfoProps?.image} alt={cardInfoProps?.title} style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', opacity: 0.1, top: 0, left: 0, zIndex: -1 }} />
      </DialogContent>
    </Dialog>
  );
};

export default ExpandedService;
