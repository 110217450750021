import React, { useEffect, useState } from 'react';
import { Typography, Button, Container, Zoom } from '@mui/material';
import {useTheme} from '@mui/material';
import ImageSlider from './ImageSlider';
import ContactUsPopup from './ContactUsPopup';

const HeroSection = () => {
  const [scrollY, setScrollY] = useState(0);
  const [width, setWidth] = useState('100%');
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleScroll = () => {
    const newScrollY = window.scrollY;
    setScrollY(newScrollY);
    const newWidth = Math.max(100 - newScrollY / 30, 50); // Adjust the divisor for speed
    setWidth(`${newWidth}%`);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const slideOutStyle = (scrollY: number, direction: string) => {
    const isRightDirection = direction === "right";

    return {
      transform: `translateX(${Math.min(scrollY / 0.6, 1000) * (isRightDirection ? 1 : -1)}px)`,
      transition: 'transform ease',
    };
  };

  return (
    <>
      <ContactUsPopup open={open} setOpen={setOpen} />
      <Container
        maxWidth="xl"
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh', 
          position: 'relative', 
          textAlign: 'center',
          background: 'linear-gradient(to right, #3f51b5, #3f51b5)',
          overflow: 'hidden',
          color: 'white',
          padding: 2,
          borderRadius: 5,
          zIndex: 1,
          width: width,
          transition: 'width ease', // Smooth transition
        }}
      >
        <ImageSlider />
        <Typography 
          variant="h2" 
          component="h1" 
          gutterBottom 
          color={theme.palette.text.primary}
          style={slideOutStyle(scrollY, "left")}
        >
          Elevate Your Tech Game
        </Typography>
        <Typography 
          variant="h5" 
          component="h2" 
          gutterBottom 
          color={theme.palette.primary.contrastText}
          style={slideOutStyle(scrollY, "right")}
        >
          Expert advice for seemless digital navigation.
        </Typography>
        <br/><br/><br/><br/>
        <Zoom in={true} timeout={2000}>
          <Button 
            variant="contained" color="primary" size="large" sx={{fontWeight: 'bold'}}
            onClick={() => setOpen(true)}
          >
            Discover Solutions!
          </Button>
        </Zoom>
      </Container>
    </>
  );
};

export default HeroSection;
