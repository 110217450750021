// Portfolio.tsx

import React, { useState} from 'react';
import { Box, Card, CardContent, CardMedia, Grid2, Typography, Fade, Alert } from '@mui/material';
import BusinessNetworking from '../assets/BusinessNetworking.webp';
import DigitalProtractor from '../assets/DigitalProtractor.png';
import SmartHomeDigitalTwin from '../assets/SmartHomeDigitalTwin.webp';
import { useTheme } from '@mui/material/styles';

interface Project {
  title: string;
  description: string;
  image: string;
  link: string;
  alertContent?: string;
}

const projects: Project[] = [
  {
    title: 'Business Networking',
    description: 'Business networking is a SAAS platform that connects businesses with other businesses. It is a platform that allows businesses to connect with each other and share information. Since small businesses may not be able to afford marketing this provides a platform where they can market',
    image: BusinessNetworking,
    link: 'https://businessnetworking.netlify.app/',
  },
  {
    title: 'Smart Home - Digital Twin',
    description: 'Currently building a smart home digital twin. It is a digital replica of a physical home. It can be used to monitor and control various devices in the home. It has use cases in home automation, security, and energy management.',
    image: SmartHomeDigitalTwin,
    link: '',
    alertContent: 'This project is still in development. Please check back later.',
  },
  {
    title: 'Digital Protractor',
    description: 'We build an IOT device that measures angles. It is a digital protractor that can be used to measure angles in real-time. It has use cases in construction, engineering, and other fields.',
    image: DigitalProtractor,
    link: '',
    alertContent: 'This project has proprietary information which cannot be shared',
  },
];

const Portfolio: React.FC = () => {
  const theme = useTheme();
  const [showAlert, setShowAlert] = useState(-1);
  const [alertContent, setAlertContent] = useState('');

  const handleClick = (link: string, key: number, alertContent?: string) => {
    if (!link){
      setShowAlert(key);
      setAlertContent(alertContent || '');
      setTimeout(() => {
        setShowAlert(-1);
        setAlertContent('');
      }, 2000);
      return;
    }
    window.open(link, '_blank');
  };
  

  return (
    <div id={'portfolio'} style={{marginLeft: 15, marginRight: 15}}>
      <Typography variant="h2" component="h2" gutterBottom> Our Projects </Typography>
        <Grid2 container spacing={4}>
          {projects.map((project, index) => (
            <Grid2 size={{xs: 12, sm: 6, md: 4}} key={index}>
              <Fade in={true} style={{ transitionDelay: `${index * 500}ms` }} key={index}>
                <Card sx={{borderRadius: 5, background: `linear-gradient(135deg, ${'#000000'}, ${theme.palette.primary.main})`}} onClick={() => handleClick(project.link, index, project.alertContent)}>
                  <Box sx={{ position: 'relative' }}>
                    <CardMedia component="img" height="400" image={project.image} alt={project.title} />
                      <Fade in={showAlert === index} timeout={{ enter: 500, exit: 500 }}>
                        <Alert severity="info" sx={{ position: 'absolute', top: 0, left: 0, right: 0, opacity: 0.8 }}>
                          {alertContent}
                        </Alert>
                      </Fade>
                  </Box>
                    <CardContent sx={{ backgroundColor: theme.palette.background.default, height: 200 }}>
                      <Typography variant="h5" component="div">
                        {project.title}
                      </Typography>
                      <br/>
                      <Typography variant="body2" color="text.secondary" textAlign={'justify'}>
                        {project.description}
                      </Typography>
                  </CardContent>
                </Card>
              </Fade>
            </Grid2>
          ))}
        </Grid2>
    </div>
  );
};

export default Portfolio;
