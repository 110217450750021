// src/App.tsx
import React, { useState, useEffect } from 'react';
import {
  CssBaseline,
  Divider,
} from '@mui/material';
import HerokuLogo from './components/HeroSection';
import { useTheme } from '@mui/material/styles';
import ServicesSection from './components/ServicesSection';
import ContactUs from './components/ContactUs';
import Portfolio from './components/Portfolio';
import CustomAppBar from './components/CustomAppBar';
import AboutUs from './components/AboutUs';
import ActionButton from './components/ActionButton';

const App: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();
  const gradientColor = `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.background.default})`

  const handleScroll = () => {
    setScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ActionButton />
      <CssBaseline />
      <CustomAppBar scrolled={scrolled} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
          background: gradientColor,
        }}
      >
        <div style={{ marginTop: '7em', width: '98%', marginBottom: '3em' }}>
          <HerokuLogo />
          <Divider style={{ margin: '2em 0', width: '100%' }} />
          <ServicesSection />
          <Divider style={{ margin: '2em 0', width: '100%' }} />
          <Portfolio />
          <Divider style={{ margin: '2em 0', width: '100%' }} />
          <AboutUs />
          <Divider style={{ margin: '2em 0', width: '100%' }} />
          <ContactUs />
          <Divider style={{ margin: '2em 0', width: '100%' }} />
        </div>
      </div>
      <div style={{ background: gradientColor, height: '3em'}} />
    </>
  );
};

export default App;
