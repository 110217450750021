import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  Typography,
  Collapse,
} from '@mui/material';
import {useTheme} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandedService from './ExpandedService';

interface ZoomPaperProps {
  image: string;
  title: string;
  text: string;
  size: { width: number; height: number };
  expandedImages: string[];
  textContent: string[];
}

const ZoomPaper: React.FC<ZoomPaperProps> = ({ image, title, text, size, expandedImages, textContent }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const paperRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (paperRef.current) {
      observer.observe(paperRef.current);
    }

    return () => {
      if (paperRef.current) {
        // eslint-disable-next-line
        const currentRef = paperRef.current;
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      }
    };
  }, []);

  return (
    <>
    <ExpandedService open={open} setOpen={setOpen} cardInfoProps={
      {
        title,
        description: text,
        image,
        expandedImages,
        textContent
      }
    } />
    <Paper
      elevation={4}
      ref={paperRef}
      onClick={() => setOpen(true)}
      sx={{
        padding: '3%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 300,
        alignItems: 'center',
        position: 'relative',
        borderRadius: 5,
        background: theme.palette.background.default,
        transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'scale(1.02)',
            zIndex: 1,
          },
        margin: 2,
        height: 500,
      }}
    >
      <Typography variant="h5" fontWeight={'bold'} color={theme.palette.primary.contrastText} textAlign={'start'}>
        {title}
      </Typography>
      <br/>
      <Collapse in={isVisible} timeout={500}>
        <Typography variant="body1" textAlign={'start'} color={theme.palette.text.secondary} style={{alignSelf: 'flex-start'}}>
          {text}
        </Typography>
      </Collapse>
      <img src={image} alt={title} style={{ width: size.width, height: size.height, borderRadius: 20 }} />
      <AddCircleOutlineIcon 
        fontSize='large'
        onClick={() => setOpen(true)}
        sx={{ 
          color: theme.palette.primary.contrastText,
          position: 'absolute',
          bottom: 20,
          right: 20,
          '&:hover': {
            color: theme.palette.text.primary,
            transform: 'rotate(90deg)',
            transition: 'transform 0.3s ease',
          },
        }} />
    </Paper>
    </>
  );
};

export default ZoomPaper;
