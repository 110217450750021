import { Dialog, DialogContent, Grid2, Typography, Zoom } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ContactUs from './ContactUs';

interface ContactUsPopupProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactUsPopup: React.FC<ContactUsPopupProps> = ({open, setOpen}) => {

    const theme = useTheme();
    const gradientColor = `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.background.default})`;

    const handleClose = () => setOpen(false);

    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
          TransitionComponent={Zoom}
          TransitionProps={{ timeout: 500 }}
          sx={{
            '& .MuiDialog-paper': {
              width: '90%',
              maxWidth: 'none',
              margin: 'auto',
              height: '90%',
            },
          }}
        >
          <DialogContent dividers sx={{ background: gradientColor }}>
            <Typography variant="h4" component="h2" sx={{ color: 'white', padding: 2 }}>
              Contact Us
            </Typography>
            <Typography variant="h6" component="h3" sx={{ color: 'white', padding: 2 }}>
              Fill out the form below and we will get back to you as soon as possible. We look forward to hearing from you!
            </Typography>
            <Grid2 container justifyContent="center" alignItems="center">
              <CloseIcon onClick={handleClose} sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }} />
            </Grid2>
            <ContactUs />
          </DialogContent>
        </Dialog>
      </>
    );
};

export default ContactUsPopup;