import { createTheme } from '@mui/material/styles';

// Dark Theme Color Palette
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1F2937', // Dark Charcoal
      paper: '#1E1E1E',   // Paper background
    },
    primary: {
      main: '#0A2BC1', // Blue
      contrastText: '#FABD2F',   // Gold
    },
    secondary: {
      main: '#94A3B8',    // Cool Gray
    },
    // accent: {
    //   main: '#FABD2F',    // Gold
    // },
    text: {
      primary: '#FFFFFF',  // Light Gray
      secondary: '#FFFFFF', // Cool Gray for secondary
    },
    success: {
      main: '#10B981',    // Success Green
    },
    error: {
      main: '#EF4444',    // Error Red
    },
  },
});

// Light Theme Color Palette
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#9aa6e7',
      paper: '#FAFAFA',   // Light paper
    },
    primary: {
      main: '#0A2BC1',    // Blue
      contrastText: '#FABD2F',   // Gold
    },
    secondary: {
      main: '#475569',    // Slate Gray
    },
    // accent: {
    //   main: '#FABD2F',    // Gold
    // },
    text: {
      primary: '#FFFFFF',  // Dark Charcoal
      secondary: '#333333', // Slate Gray for secondary
    },
    success: {
      main: '#10B981',    // Success Green
    },
    error: {
      main: '#EF4444',    // Error Red
    },
  },
});
