import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import HerokuImage from '../assets/HerokuImg.jpg';
import HerokuImage2 from '../assets/HerokuImg2.jpg';
import HerokuImage3 from '../assets/HerokuImage3.jpg';

interface Image {
  imgPath: string;
}

const images: Image[] = [
  {
    imgPath: HerokuImage,
  },
  {
    imgPath: HerokuImage2,
  },
  {
    imgPath: HerokuImage3,
  }
];

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Arrows: React.FC<{ prev: () => void; next: () => void }> = ({ prev, next }) => {
  const boxStyle = { 
    cursor: 'pointer', margin: 5, opacity: 0.5, fontSize: '2rem', color: 'white', zIndex: 1,
    '&:hover': {
      opacity: 1,
    },
  }
  return (
  <Box sx={{ position: 'absolute', top: '50%', width: '100%', display: 'flex', justifyContent: 'space-between', transform: 'translateY(-50%)' }}>
    <Box onClick={prev} sx={boxStyle}>
      &#10094;
    </Box>
    <Box onClick={next} sx={boxStyle}>
      &#10095;
    </Box>
  </Box>
)};

const Dots: React.FC<{ activeIndex: number; onClick: (index: number) => void }> = ({ activeIndex, onClick }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, position: 'absolute', bottom: 10, width: '100%' }}>
    {images.map((_, i) => (
      <Box
        key={i}
        onClick={() => onClick(i)}
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: activeIndex === i ? 'white' : 'grey',
          mx: 0.5,
          cursor: 'pointer',
        }}
      />
    ))}
  </Box>
);

const SliderContent: React.FC<{ activeIndex: number }> = ({ activeIndex }) => (
  <Box sx={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
    {images.map((slide, i) => (
      <Box
        key={i}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: activeIndex === i ? 1 : 0,
          transition: 'opacity 0.5s ease',
          animation: `${fadeIn} 0.5s`,
        }}
      >
        <img alt='' src={slide.imgPath} style={{ width: '100%', height: '100%', objectFit: 'fill', display: 'block', }} />
      </Box>
    ))}
  </Box>
);

const Slider: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}>
      <SliderContent activeIndex={activeIndex} />
      <Arrows
        prev={() => setActiveIndex(activeIndex < 1 ? images.length - 1 : activeIndex - 1)}
        next={() => setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1)}
      />
      <Dots activeIndex={activeIndex} onClick={(index) => setActiveIndex(index)} />
    </Box>
  );
};

export default Slider;
