import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import ContactUsPopup from './ContactUsPopup';

const ActionButton: React.FC = () => {

    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleOnClick = () => {
      setOpen(true);
    };

    const buildContactButton = () => {
        return <Button
          variant="contained"
          color='primary'
          size="large"
          onClick={handleOnClick}
          sx={{
            marginTop: '20px',
            color: 'white',
            background: theme.palette.primary.contrastText,
            borderRadius: 5,
            position: 'fixed',
            bottom: 25,
            right: 25,
            height: 50,
            zIndex: 10,
            ":hover": {
              background: `linear-gradient(250deg, ${theme.palette.primary.contrastText}, ${theme.palette.primary.main})`,
              ".MuiSvgIcon-root": {
                transform: 'rotate(90deg)',
                transition: 'transform 0.5s ease',
              },
            },
          }}
        >
          <>
            <AddIcon sx={{ marginRight: 1, transition: 'transform 0.5s ease' }} />
            <Typography fontWeight={'bold'}>Get A Quote</Typography>
          </>
        </Button>
      }

    return (
      <>
        {buildContactButton()}
        <ContactUsPopup open={open} setOpen={setOpen} />
      </>
    );
};

export default ActionButton;