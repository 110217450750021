// src/AboutUs.tsx

import React from 'react';
import { Typography, Grid2 as Grid, Paper, Box } from '@mui/material';
import AmazonLogo from '../assets/Amazon.png';
import NasdaqLogo from '../assets/Nasdaq.png';
import IBMLogo from '../assets/IBM.png';
import TMULogo from '../assets/TMU.png';
import MUNLogo from '../assets/Mun.png';
import VerafinLogo from '../assets/Verafin.png';
import GenesisCenterLogo from '../assets/GenesisCenter.png';
import { useTheme } from '@mui/material/styles';

const AboutUs: React.FC = () => {

    const theme = useTheme();

    const buildIndustryExperience = () => {
        const companies = [
          { name: 'Amazon', logo: AmazonLogo },
          { name: 'Nasdaq', logo: NasdaqLogo },
          { name: 'Verafin', logo: VerafinLogo },
          { name: 'IBM', logo: IBMLogo },
          { name: 'Toronto Metropolitan University', logo: TMULogo },
          { name: 'Memorial University', logo: MUNLogo },
          { name: 'Genesis Center', logo: GenesisCenterLogo },
        ];
    
        const isMobile = window.innerWidth < 600;

        return (
          <>
            <Typography variant="h4" component="h2" gutterBottom> Industry Experience </Typography>
            <Box sx={{ overflow: 'hidden', position: 'relative', mt: 4 }}>
              <Box
                sx={{
                  display: 'flex',
                  animation: `scroll ${isMobile ? '50' : '80'}s linear infinite`,
                  whiteSpace: 'nowrap',
                }}
              >
                {Array.from({ length: 10 }).flatMap((_, i) =>
                  companies.map((company, index) => (
                  <Box key={`${i}-${index}`} sx={{ textAlign: 'center', mx: 6, display: 'inline-block' }}>
                    <img src={company.logo} alt={company.name} style={{ width: 130, height: 100 }} />
                  </Box>
                  ))
                )}
              </Box>
              <style>
                {`
                  @keyframes scroll {
                    0% { transform: translateX(0); }
                    100% { transform: translateX(-1000%); }
                  }
                `}
              </style>
            </Box>
          </>
        );
      };


    const buildDataGrid = (title: string, text: string) => {
        return (<Grid size={{xs: 12, md: 6}}>
            <Paper elevation={3} sx={{ borderRadius: 5, padding: 2, background: theme.palette.background.default }}>
                <Typography variant="h6" fontWeight={'bold'} >{title}</Typography>
                <br/>
                <Typography>{text}</Typography>
            </Paper>
        </Grid>)
    };
    
    return (
        <div id={'about-us'} style={{marginLeft: 15, marginRight: 15}}>
            <Typography variant="h2" component="h2" gutterBottom> About Us </Typography>
            <Typography variant="h5" align="center" paragraph>
                We are a dedicated team committed to providing the best services.
            </Typography>
            <Grid container spacing={4}>
                {buildDataGrid('Our Mission', 'To build cutting-edge software solutions that transform ideas into reality, driving innovation and delivering seamless experiences. We strive for excellence, reliability, and a customer-centric approach, ensuring every product exceeds expectations and adapts to the landscape.')}
                {buildDataGrid('Our Vision', 'To be a leader in innovative software solutions that inspire trust and transform ideas into reality. We envision a future where our commitment to excellence and adaptability drives seamless experiences, empowering clients to thrive in the ever-evolving digital landscape.')}
            </Grid>
            <br/> <br/>
            {buildIndustryExperience()}
        </div>
    );
    };

    export default AboutUs;
