import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Container, Typography, Box, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ContactUs: React.FC = () => {
    const theme = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const textFieldStyle = {
    '& label.Mui-focused': { color: theme.palette.primary.contrastText },
    '& .MuiOutlinedInput-root': { 
      '&.Mui-focused fieldset': { borderColor: theme.palette.primary.contrastText } 
    },
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setIsAlertOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { top } = ref.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setIsVisible(true);
        }
        else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const buildSubmitDialog = () => {
    const gradientColor = `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.background.default})`
    return (
      <Dialog open={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
        <div style={{
          background: gradientColor
        }}>

        <DialogTitle fontWeight={'bold'}>Thank you for contacting us!</DialogTitle>
        <DialogContent>
            <Typography variant="body1">
              We would love to hear from you. However, we are still working on this feature and your message will not reach us at the moment.
              <br /><br />
              Please send us an email at <a href="mailto:sahil@ltiora.com" style={{ color: theme.palette.primary.contrastText }}>sahil@ltiora.com</a> to get in touch with us. 
              <br /><br />
              Thank you for your understanding.
            </Typography>
        </DialogContent>
        <Alert severity="error">We are still working on this feature. Please send us an email to get in touch.</Alert>
        <DialogActions sx={{justifyContent:'space-between'}}>
          <Button onClick={() => setIsAlertOpen(false)} sx={{color: theme.palette.error.main}}>
            Close
          </Button>
          <Button onClick={() => window.location.href = 'mailto:sahil@ltiora.com'} sx={{color: theme.palette.primary.contrastText}}>
            Send Us an Email
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    )
  };

  return (
    <>
    {buildSubmitDialog()}
    <Container id={'contact-us'} maxWidth="lg" sx={{ mt: 4, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
      <Box
        sx={{
          flex: 1,
          opacity: 0,
          transform: 'translateX(-100px)',
          transition: 'opacity 0.5s ease, transform 0.5s ease',
        }}
        className={`slide-in ${isVisible ? 'visible' : ''}`}
      >
        <Typography variant="h4" fontWeight={'bold'} component="h2" gutterBottom color={theme.palette.primary.contrastText}>
            Begin your Assessment
        </Typography>
        <Typography variant="body1" paragraph>
            We're a full-service digital agency that specializes in web design, web development, and digital marketing. We're here to help your business grow and succeed online!
        </Typography>
        <Typography variant="body1" paragraph>
            We have a proven track record in web design, web development, and result-driven digital marketing strategies. We're confident that we can help your business grow and succeed online!
        </Typography>
      </Box>

      <Box
        ref={ref}
        sx={{
          flex: 1,
          opacity: 0,
          transform: 'translateX(-100px)',
          transition: 'opacity 0.5s ease, transform 0.5s ease',
        }}
        className={`slide-in-right ${isVisible ? 'visible' : ''}`}
      >
        <Typography variant="h4" fontWeight={'bold'} component="h1" gutterBottom color={theme.palette.primary.contrastText}>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            // required
            sx={textFieldStyle}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            // required
            sx={textFieldStyle}
          />
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            // required
            sx={textFieldStyle}
          />
          <br /><br />
          <Button variant="contained" color="primary" type="submit" sx={{fontWeight: 'bold'}}>
            Send Message
          </Button>
        </form>
      </Box>
      
      <style>{`
        .slide-in {
          opacity: 0;
          transform: translateX(-100px);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }
        .slide-in.visible {
          opacity: 1;
          transform: translateX(0);
        }
        .slide-in-right {
          opacity: 0;
          transform: translateX(100px);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }
        .slide-in-right.visible {
          opacity: 1;
          transform: translateX(0);
        }
      `}</style>
    </Container>
    </>
  );
};

export default ContactUs;
